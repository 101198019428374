import React from "react";

import { Container, Wrapper, Box } from "../../components/util";
import Image from "../../components/image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const About = (props) => (
  <Layout>
    <Seo
      title="The Karmly Team | About Us"
      description="Work. Life. Success. That's what makes us tick - and we’re committed to helping NZ business owners achieve all three. Find out about the team at FlexiTime."
      pathname={props.location.pathname}
    />
    <Container>
      <Wrapper maxWidth={850}>
        <Box stackGap={50}>
          <h1 className="-textCenter">About Us</h1>
          <div className="font-bump">
            <p>
              The future of work is contingent. That means recruitment agencies
              will be more important than ever before. But in an increasingly
              cut throat market your agency needs every possible advantage to
              give you an edge over your competitors. You need to be smarter,
              faster, more agile and data driven. That’s where we come in.
            </p>
            <p>
              Karmly is a timesheet and billing platform for companies with a
              contingent workforce. By providing a beautiful and intuitive
              online experience for contractors and clients, Karmly streamlines
              the time submission, approval and invoicing process to help you
              increase the accuracy of payroll and get paid faster.
            </p>
            <p>
              Our mission is to help you scale your recruitment agency by
              letting you focus on what you do best – recruiting. Karmly takes
              care of the administration of timesheets, approvals and invoicing
              so you get paid faster and can spend your precious time placing
              more candidates. We give you real time revenue and margin
              analytics and consultant performance data so you can see where
              you’re winning and where you’re not.
            </p>
            <p>
              Karmly sits beautifully in between your CRM/ATS, payroll and
              accounting software, giving you an end-to-end data flow you’ve
              only dreamed of until now. Think of us as connecting the dots in
              your own little market-leading recruitment tech ecosystem.
            </p>
            <p>
              With offices in Wellington and Sydney, we’re helping hundreds of
              customers worldwide automate the complex, repetitive, and
              incredibly important day-to-day requirements of managing a
              contingent workforce.
            </p>
          </div>
          <Box stackGap={20}>
            <Image
              filename="InvoxyTeam.jpg"
              alt="Meet the Karmly Team"
              rounded
              addShadow
            />
            <p css={{ color: "#999" }} className="-textCenter">
              (Some of) The Karmly Team
            </p>
          </Box>
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default About;
